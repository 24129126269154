//import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Section3 from "../components/Section3";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";


function Home() {

  // immer zu 430 scrollen
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <>
      <Helmet>
        <title>Willkommen - Freie Schule</title>
        <meta name="description" content="Freie Oberschule Boxberg" />
      </Helmet>

      <div className="text-start">
        <Section3 />
      </div>
    </>
  );
}

export default Home;
