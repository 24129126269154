import React from "react";

export default function LoginForm({ handleClick }) {
  return (
    <div className="text-start" style={{ maxWidth: "200px" }}>
      <div className="msg"></div>
      <form onSubmit={handleClick}>
        <label className="form-label" htmlFor="username">
          Username:
        </label>
        <input
          className="form-control"
          type="text"
          name="username"
          id="username"
        />
        <br />
        <label className="form-label" htmlFor="password">
          Password:
        </label>
        <input
          className="form-control"
          type="password"
          name="password"
          id="password"
        />
        <br />
        <button className="btn btn-primary" type="submit">
          Log In
        </button>
      </form>
    </div>
  );
}
