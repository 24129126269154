import React, { useState } from "react";
import { addDoc, collection } from "firebase/firestore";
import { db, auth } from "../firebase-config";
import { useNavigate } from "react-router-dom";

function CreatePost() {
  const [title, setTitle] = useState("");
  const [postText, setPostText] = useState("");
  const postsCollection = collection(db, "posts");

  let navigate = useNavigate();
  const createPost = async () => {
    await addDoc(postsCollection, {
      title,
      postText,
      author: { name: auth.currentUser.displayName, id: auth.currentUser.uid },
    });

    navigate("/");
  };

  return (
    <>
      <div className="container text-start">
        <h1>Create Post</h1>

        <div className="form-group mb-3">
          <label>Title</label>
          <input
            className="form-control"
            placeholder="Title:"
            onChange={(e) => {
              setTitle(e.target.value);
            }}
          />
        </div>
        <div className="form-group mb-3">
          <label>Post</label>
          <textarea
            rows="10"
            className="form-control"
            placeholder="Post:"
            onChange={(e) => {
              setPostText(e.target.value);
            }}
          />
        </div>
        <button className="btn btn-warning" onClick={createPost}>
          Create Post
        </button>
      </div>
    </>
  );
}

export default CreatePost;
