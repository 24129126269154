import React from "react";
import { Container } from "react-bootstrap";

export default function OfficeStripe() {
  return (
    <div className="office-stripe">
      <Container>
        <div className="d-flex justify-content-between">
          <div className="">
            <strong>Sprechzeiten Schulbüro: </strong>Frau Yvonne Markwitz
          </div>
          <div className="telefon">
            Mo-Fr 7.00 - 13.00 Uhr - Tel.{" "}
            <a href="tel:+493577432102">035 774 32 102</a>
          </div>
        </div>
      </Container>
    </div>
  );
}
