import React from "react";
import { Container, Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

import fsblogo from "../img/fsb-logo.png";
import { useState } from "react";
import SubNavDropdown from "./SubNavDropdown";

export default function BootstrapNavbar() {
  const [expanded, setExpanded] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleMenuItemClick = () => {
    setIsDropdownOpen(false);
    setExpanded(false);
    console.log(isDropdownOpen);
  };

  const links = [
    { to: "/schoolgear", label: "Schulkleidung" },
    { to: "/tv", label: "Trägerverein" },
    { to: "/tvaktuell", label: "TV-Aktuell" },
    { to: "/tvsatzung", label: "Satzung" },
    // { to: "/design", label: "Entwickkler" },
  ];
  const fvTitle = "Förderverein";

  const links_fv = [
    { to: "/Satzung", label: "Satzung" },
    { to: "/bankvb", label: "Bankverbindung" },
    { to: "/eintritt", label: "Eintrittserklärung" },
    { to: "/unterstuetzer", label: "Unterstützer" },
  ];
  const schoolTitle = "Schule";
  return (
    <Navbar
      expanded={ expanded }
      bg="dark"
      variant="dark"
      fixed="top"
      expand="lg"
    >
      <Container>
        <Navbar.Brand>
          <img
            src={ fsblogo }
            width="38"
            height="38"
            className="d-inline-block align-top"
            alt="React Bootstrap logo"
          />
          <span style={ { paddingLeft: "15px" } }>
            Freie Schule Boxberg /O.L.
          </span>
        </Navbar.Brand>
        <Navbar.Toggle
          onClick={ () => setExpanded(expanded ? false : "expanded") }
          aria-controls="basic-navbar-nav"
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Link
              to="/home"
              className="nav-link"
              onClick={ () => setExpanded(false) }
            >
              Willkommen
            </Link>
            {/* SUPER-WICHTIG!! Link-Komponente verwenden für App im Unterordner */ }
            <Link
              to="/about"
              className="nav-link"
              onClick={ () => setExpanded(false) }
            >
              Chronik
            </Link>

            <Link
              onClick={ () => setExpanded(false) }
              className="nav-link"
              to="/single/9"
            >
              Über uns
            </Link>

            <SubNavDropdown
              handleMenuItemClick={ handleMenuItemClick }
              links={ links }
              title={ schoolTitle }
            />
            <SubNavDropdown
              handleMenuItemClick={ handleMenuItemClick }
              links={ links_fv }
              title={ fvTitle }
            />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
