import React from "react";

export default function Error() {
  return (
    <div className="container">
      <div className="errorbox">
        <h2>Oooops ... Error 404!</h2>
        <p>Diese Seite existiert hier nicht!</p>
      </div>
    </div>
  );
}
