import React, { useState, useEffect } from "react";
import axios from "axios";
//import { Link } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Subnavbar from "../components/Subnavbar";
import { useLocation } from "react-router-dom";



export default function About() {
  const [htmlContent, setHtmlContent] = useState({ __html: "" });
  // immer zu 430 scrollen
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 430);
  }, [location]);

  // loading PHP
  useEffect(() => {
    // loading php posts
    const webUrl = "https://www.fs-boxberg.de/server/posts.php";
    //const localUrl = "https://fsbdevserver:8890/server/posts.php";

    // Send a GET request to the URL with axios
    axios
      .get(webUrl)
      .then((response) => {
        // Set the HTML content to the response data:
        setHtmlContent({ __html: response.data });
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);



  return (
    <>
      <Helmet>
        <title>Chronik - Freie Schule</title>
        <meta
          name="description"
          content="Schulchronik Freie Oberschule Boxberg"
        />
      </Helmet>
      <div className="bg-light pt-3" id="schoolinfo">
        <Subnavbar />

        <Container>
          <Row>
            <Col>

              {/* this element will have its inner HTML set to the HTML content from the response */ }
              <div id="myElement" dangerouslySetInnerHTML={ htmlContent } />

            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
