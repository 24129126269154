import React, { useState, useEffect } from "react";
import axios from "axios";
// import styled from "styled-components";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
// const RedBold = styled.span`
//   color: red;
//   font-weight: bold;
// `;
export default function RowInfo() {
  const [data, setData] = useState([]);
  //const [loading, setLoading] = useState(true);
  function reverseDate(dateStr) {
    const date = dateStr.split("-");
    return `${date[2]}.${date[1]}.${date[0]}`;
  }

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios(
        "https://www.fs-boxberg.de/server/server_startbox.php"
      );
      setData(result.data);
      //console.log(result.data);
    };

    fetchData();
  }, []);
  // function replaceNewLineWithBr(text) {
  //   return text.split("\n").map((item, index) => (
  //     <React.Fragment key={index}>
  //       {item}
  //       <br />
  //     </React.Fragment>
  //   ));
  // }

  function replaceNewLineWithBrAndStrong(text) {
    return text.split("\n").map((line, i) => (
      <span key={i}>
        {line
          .split(/(\*.*?\*)/)
          .map((part, j) =>
            part.startsWith("*") && part.endsWith("*") ? (
              <strong key={j}>{part.slice(1, -1)}</strong>
            ) : (
              part.replace(/(\*|\n)/g, "")
            )
          )}
        <br />
      </span>
    ));
  }

  return (
    <>
      <h2 className="section-head text-center">Aktuell</h2>
      <div className="row">
        {data.map((item) => (
          <div className="col-lg-4 gy-4">
            <Card key={item.id} className="h-100 mb-3">
              <Card.Body>
                <h2>{item.headline}</h2>
                <p>Erstellt am {reverseDate(item.publish_date)}</p>
                <p>{replaceNewLineWithBrAndStrong(item.bodytext)}</p>
                {item.link !== "none" ? (
                  <a
                    className="btn btn-outline-primary"
                    href={item.link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Mehr lesen &raquo;
                  </a>
                ) : (
                  <Link to={`/single/${item.post_id}`}>
                    <button className="btn btn-outline-primary">
                      Zum Artikel &raquo;
                    </button>
                  </Link>
                )}
              </Card.Body>
            </Card>
          </div>
        ))}
      </div>
    </>
  );
}
