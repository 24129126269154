import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function Subnavbar() {
  const links = [
    { to: '/single/9', label: 'Klassen' },
    { to: '/single/10', label: 'Lehrer & Team' },
    { to: '/single/7', label: 'Schülerverkehr' },
    { to: '/single/6', label: 'Unterrichtszeiten' }
  ];

  return (
    <div>
      <Navbar bg="light" expand="lg">
        {/* Adding margin left for layout reasons */}
        <Container>
          <Navbar.Brand className="card-subtitle px-2">
            Infos der Schule
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto px-2">
              {links.map((link, index) => (
                <Nav.Link key={index}>
                  <Link to={link.to} className="nav-link">
                    {link.label}
                  </Link>
                </Nav.Link>
              ))}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}
