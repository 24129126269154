import React from 'react';
import ImageGrid from '../components/ImageGrid';
import { Container } from 'react-bootstrap';

// Beispiel-Array mit Bilddaten
const images = [
    { src: 'images/001.jpg', alt: 'Bild 1' },
    { src: 'images/002.jpg', alt: 'Bild 2' },
    { src: 'images/003.jpg', alt: 'Bild 3' },
    { src: 'images/006.jpg', alt: 'Bild 6' },
    { src: 'images/004.jpg', alt: 'Bild 4' },
    { src: 'images/005.jpg', alt: 'Bild 5' },
    { src: 'images/007.jpg', alt: 'Bild 7' },
    { src: 'images/008.jpg', alt: 'Bild 8' },
    // Weitere Bilder hier hinzufügen
];
export default function SchoolClothes() {
    return (
        <>
            <Container>
                <h1 className="my-3">Schulkleidung</h1>
                <ImageGrid images={ images } />
            </Container>

            {/* <ImageGrid /> */ }
        </>
    );
}
