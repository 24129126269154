import React from "react";
import { NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

function SubNavDropdown({ handleMenuItemClick, links, title }) {
  const dropdownTitle = title || "Standardtitel"; // Standardtext, wenn title nicht vorhanden ist
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLinkClick = () => {
    setIsDropdownOpen(false);
    handleMenuItemClick();
  };

  return (
    <>
      <NavDropdown
        title={dropdownTitle}
        id="nav-dropdown"
        show={isDropdownOpen}
        align="end"
        onToggle={toggleDropdown}
      >
        {links.map((link, index) => (
          <Link
            key={index}
            onClick={handleLinkClick}
            className="dropdown-item"
            to={link.to}
          >
            {link.label}
          </Link>
        ))}
      </NavDropdown>
    </>
  );
}

export default SubNavDropdown;
