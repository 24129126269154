import "./App.css";

import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";

import BootstrapNavbar from "./components/BootstrapNavbar";
import Market from "./pages/Market";
import Jumbtron from "./components/Jumbtron";

import Footer from "./components/Footer";
import Error from "./pages/Error";
import Newpage from "./pages/Newpage";
import CreatePost from "./pages/CreatePost";

import Login from "./pages/Login";
import Contact from "./pages/Contact";
import Single from "./pages/Single";
import OfficeStripe from "./components/OfficeStripe";
import MultiPage from "./pages/MultiPage";
import Designed from "./pages/Designed";
import SchoolClothes from "./pages/SchoolClothes";



function App() {
  //TV-Links doppelt?
  const links = [
    { to: "/schoolgear", label: "Schulkleidung" },
    { to: "/tvaktuell", label: "TV-Aktuell" },
    { to: "/tv", label: "Trägerverein" },
    { to: "/tvsatzung", label: "Satzung" },
  ];



  return (
    <>
      <BootstrapNavbar />
      <Jumbtron />
      <OfficeStripe />
      <Routes>
        {/* pass element in JSX with self closing Route -- important - <Newpage headline="Schulkleidung" title="Schule"
              showSubnavbar={ true }
              links={ links } />*/ }
        <Route
          path="/schoolgear"
          element={
            <SchoolClothes />
          }
        />
        <Route
          path="/tv"
          element={
            <Newpage
              headline="Trägerverein"
              title="Schule"
              showSubnavbar={ true }
              links={ links }
            />
          }
        />
        <Route
          path="/tvsatzung"
          element={
            <Newpage headline="Satzung" title="Schule"
              showSubnavbar={ true }
              links={ links } />
          }
        />
        <Route
          path="/tvaktuell"
          element={
            <Newpage headline="Aktuelles" title="Schule"
              showSubnavbar={ true }
              links={ links } />
          }
        />
        <Route path="/design" element={ <Designed /> } />
        <Route path="/createpost" element={ <CreatePost /> } />
        <Route path="/home" element={ <Home /> } />
        <Route path="/" element={ <Home /> } />
        <Route path="/kontakt" element={ <Contact /> } />
        <Route path="/login" element={ <Login /> } />
        <Route path="/about" element={ <About /> } />
        <Route path="/market/:productId" element={ <Market /> } />
        {/* display dynamic single post mit ID */ }
        <Route path="/single/:postId" element={ <Single /> } />

        <Route path="/bankvb" element={ <MultiPage title="Bankverbindung" /> } />
        <Route path="/Satzung" element={ <MultiPage title="Satzung" /> } />
        <Route path="/eintritt" element={ <MultiPage title="Eintritt" /> } />
        <Route path="/unterstuetzer" element={ <MultiPage title="Unterstützer" /> } />
        <Route path="*" element={ <Error /> } />
      </Routes>

      <Footer />
    </>
  );
}
export default App;
