import React, { useState, useEffect } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import axios from "axios";

export default function Jumbotron() {
  const [content, setContent] = useState("");
  const [show, setShow] = useState(false);
  const [password, setPassword] = useState('');
  //const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const response = await axios.get(
        "https://www.fs-boxberg.de/server/timetablecontent.php"
      );
      setContent(response.data);
    }
    fetchData();
  }, []);

  const pdfString = `https://fs-boxberg.de/server/uploads/${content}`;
  const freiarbeit = `https://fs-boxberg.de/freiarbeitpage.php`;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handlePasswordChange = (e) => setPassword(e.target.value);

  const handleSubmit = (e) => {
    e.preventDefault();
    // set password here
    if (password === 'Konfuzius') {
      //setIsAuthenticated(true);
      handleClose();
      window.open(pdfString, '_blank');
    } else {
      alert('Falsches Passwort');
    }
  };

  return (
    <div className="jumbotron">
      <h1>Willkommen! </h1>
      <div className="button-cont">
        <Button variant="warning" href="/app/home#aktuell">
          Aktuelles
        </Button>
        <Button variant="primary" onClick={ handleShow }>
          Stundenplan
        </Button>
        <Button variant="success" href={ freiarbeit } target="_blank">
          Freiarbeit
        </Button>
      </div>
      <p className="labertext">
        &quot; Erzähle mir und ich vergesse,
        <br />
        zeige mir und ich erinnere,
        <br />
        lass es mich tun und ich verstehe!&quot;
      </p>
      <p>Konfuzius</p>

      <Modal show={ show } onHide={ handleClose }>
        <Modal.Header closeButton>
          <Modal.Title>Passwort erforderlich</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={ handleSubmit }>
            <Form.Group controlId="formPassword">
              <Form.Label>Passwort</Form.Label>
              <Form.Control
                type="password"
                placeholder="Passwort eingeben"
                value={ password }
                onChange={ handlePasswordChange }
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-3">
              Bestätigen
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
