import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import SubnavDynamic from '../components/SubnavDynamic';
import { Helmet } from 'react-helmet';

function MultiPage(props) {

  const links = [
    { to: '/Satzung', label: 'Satzung' },
    { to: '/bankvb', label: 'Bankverbindung' },
    { to: '/eintritt', label: 'Eintrittserklärung' },
    { to: '/unterstuetzer', label: 'Unterstützer' }
  ];
  return (
    <>
      <Helmet>
        <title>{props.title} - Freie Schule</title>
        <meta
          name="description"
          content="Schulinfo, Schulessen, Trägerverein"
        />
      </Helmet>
      <div className='bg-light'>
        <SubnavDynamic links={links} title="Förderverein" />
        <Container>
          <Row>
            <Col>
              <div className="page">
                {props.title === "Satzung" && (
                  <Card style={{ maxWidth: "100%" }}>
                    <Card.Body>
                      <h2>Satzung</h2>
                      <p>Satzung des Vereins „Förderverein Freie Schule Boxberg/O.L. e.V.“</p>
                      <h4>§ 1 Name und Sitz des Vereins, Geschäftsjahr</h4>
                      <ol>
                        <li>Der Verein führt den Namen „Förderverein Freie Schule Boxberg/O.L.“ mit Sitz in 02943 Boxberg/O.L.</li>
                        <li>Das Geschäftsjahr beginnt am 1. Januar und endet am 31. Dezember eines Jahres.
                        </li>
                      </ol>
                      <h4>§ 2 Zweck, Aufgaben, Gemeinnützigkeit des Vereins</h4>
                      <ol>
                        <li className='pb-3'>Zweck des Vereins ist die Beschaffung von Mitteln zur Förderung der Bildung und Erziehung an einer Schule in freier Trägerschaft einer als gemeinnützig anerkannten juristischen Person des privaten Rechts. Der Verein „Freie Schule Boxberg/O.L.“ verfolgt ausschließlich und unmittelbar gemeinnützige Zwecke im Sinne des Abschnitts „Steuerbegünstigte Zwecke“ der Abgabenordnung.</li>
                        <li className='pb-3'>Der Verein unterstützt insbesondere die humanistische Ausrichtung der schulischen Bildung und Erziehung unter Einbeziehung der regionalen Besonderheiten. Der Verein ist selbstlos tätig, er verfolgt nicht in erster Linie eigenwirtschaftliche Zwecke.
                        </li>
                        <li className='pb-3'>Alle Leistungen des Vereins erfolgen freiwillig. Ein Rechtsanspruch auf sie besteht nicht.</li>
                        <li className='pb-3'>Der Verein ist politisch und konfessionell neutral.</li>
                        <li className='pb-3'>Die Arbeit des Vereins im Sinne des Satzungszwecks richtet sich besonders darauf, durch Beiträge, Geld- und Sachspenden und sonstige Unterstüt­zung und Förderung aller Art eine Schule in freier Trägerschaft zu unterstützen.
                        </li>
                        <li className='pb-3'>Mittel des Vereins dürfen nur für die satzungsmäßigen Zwecke verwendet werden. Die Mitglieder des Vereins erhalten keine Zuwendungen oder Gewinnanteile aus den Mitteln des Vereins. Es darf niemand durch unverhältnismäßig hohe Ausgaben oder durch Vergütungen, die dem Zweck des Vereins fremd sind, begünstigt werden.s</li>
                      </ol>
                      <h4>§ 3 Erwerb der Mitgliedschaft</h4>
                      <ol>
                        <li className='pb-3'>Mitglieder können alle natürlichen und juristischen Personen werden, die die Vereinszwecke fördern wollen.</li>
                        <li className='pb-3'>Ein schriftlicher Aufnahmeantrag ist an den Vorstand zu richten. Ersatzweise genügt die Abgabe einer unterschriebenen Beitrittserklärung. Mit dem Antrag erkennt der Bewerber für den Fall seiner Aufnahme die Satzung an. Ein Aufnahmeanspruch besteht nicht.</li>
                        <li className='pb-3'>Über die Aufnahme entscheidet der Vorstand durch Beschluss. Die Entscheidung ist dem Bewerber mitzuteilen. Sie bedarf keiner Begründung.</li>
                        <li className='pb-3'>Die Mitgliedschaft beginnt mit dem Aufnahmebeschluss.</li>

                      </ol>
                      <h4>§ 4 Beendigung der Mitgliedschaft</h4>
                      <ol>
                        <li className='pb-3'>Die Mitgliedschaft im Verein erlischt durch Tod, Ausschluss oder durch Austritt.</li>
                        <li className='pb-3'>Der Austritt erfolgt am Ende eines Geschäftsjahres durch eine schriftliche Erklärung, die spätestens 1 Monat vorher dem Vorstand zu übergeben ist.</li>
                        <li className='pb-3'>
                          3. Ein Mitglied kann durch Beschluss der Mitgliederversammlung aus dem Verein ausgeschlossen werden, wenn:
                          <ol className='pt-3'>
                            <li className='pb-3'> schuldhaft das Ansehen oder die Interessen des Vereins in schwerwiegender Weise geschädigt oder die ihm nach der Satzung obliegenden Pflichten wiederholt verletzt hat oder</li>
                            <li className='pb-3'> mehr als 3 Monate mit der Zahlung seiner Mitgliedsbeiträge im Rückstand ist und trotz schriftlicher Mahnung unter Androhung des Ausschlusses die rückständigen Beiträge nicht eingezahlt hat.</li>

                          </ol>
                        </li>
                        <li className='pb-3'>Der Beschluss ist schriftlich zu übermitteln, dem Betreffenden steht Berufung an die nächste Mitgliederversammlung zu, deren Beschluss ist endgültig.</li>
                        <li className='pb-3'>Durch Austritt oder Ausschluss erlöschen alle Ansprüche an dem Verein.</li>
                      </ol>
                      <h4>§ 5 Rechte und Pflichten der Mitglieder</h4>
                      <ol>
                        <li className='pb-3'>Jedes Mitglied hat das Recht, sich bei der Umsetzung der Ziele des Vereins aktiv einzubringen und an gemeinsamen Veranstaltungen teilzunehmen. Jedes volljährige Mitglied hat gleiches Stimm- und Wahlrecht in der Mitgliederversammlung.</li>
                        <li className='pb-3'>Jedes Mitglied hat die Pflicht, die Interessen des Vereins zu fördern, insbesondere regelmäßig seine Mitgliedsbeiträge zu leisten und, soweit es in seinen Kräften steht, vereinsintern aktiv mitzuarbeiten.</li>

                      </ol>
                      <h4>§ 6 Aufnahmegebühr und Mitgliedsbeiträge</h4>
                      <ol>
                        <li className='pb-3'>Bei der Aufnahme in den Verein ist keine Aufnahmegebühr zu zahlen. Jedes volljährige Mitglied hat einen jährlich im Voraus zu zahlenden Mitgliedsbeitrag zu entrichten.</li>
                        <li className='pb-3'>Die Höhe der Mitgliedsbeiträge wird von der Mitgliederversammlung festgelegt. Dabei ist die Offenheit des Vereins für die Allgemeinheit angemessen zu berücksichtigen.</li>
                        <li className='pb-3'>Der Mitgliedsbeitrag wird vier Wochen nach Beitritt zum Verein bzw. vier Wochen nach Beginn des Geschäftsjahres fällig.</li>

                      </ol>
                      <h4>§ 7 Organe des Vereins</h4>
                      <ol>
                        <li className='pb-3'>Organe des Vereins sind:
                          <ul className='pt-3'>
                            <li className='pb-3'>a) die Mitgliederversammlung,</li>
                            <li className='pb-3'>b) der Vorstand mit dem 1. und dem 2. Vorsitzenden, dem Schatzmeister und dem Schriftführer,</li>
                            <li className='pb-3'>c) der erweiterte Vorstand mit drei Beisitzern.</li>
                          </ul>
                        </li>


                      </ol>
                      <h4>§ 8 Vorstand</h4>
                      <ol>
                        <li className='pb-3'>Der Vorstand führt die Geschäfte des Vereins, soweit nicht auf Grund Gesetz oder Satzung die Mitgliederversammlung zuständig ist.</li>
                        <li className='pb-3'>Die gerichtliche und außergerichtliche Vertretung des Vereins nach § 26 Abs. 2 BGB obliegt dem 1. und 2. Vorsitzenden. Beide sind einzelnd zur Vertretung des Vereins befugt.</li>
                        <li className='pb-3'>Die Vorstandsmitglieder werden von der Mitgliederversammlung jeweils einzeln für die Dauer von zwei Jahren gewählt. Wiederwahl ist zulässig. Es können nur Mitglieder des Vereins in den Vorstand berufen werden. Die Amtszeit eines Vorstandsmitgliedes endet mit der Übernahme des Amts durch den Nachfolger oder mit dem Ende der Mitgliedschaft im Verein. Scheidet ein Vorstandsmitglied vorzeitig aus, so kann durch die verbleibenden Mitglieder des Vorstands für die restliche Amtszeit ein Amtsnachfolger bestellt werden.</li>
                        <li className='pb-3'>Der Vorstand entscheidet durch Beschlüsse mit einfacher Stimmenmehrheit. Bei Stimmengleichheit gilt ein Antrag als abgelehnt. Die Einladung zu Vorstands-sitzungen ergeht schriftlich unter Angabe der Tagesordnung. Die Übermittlung per Telefax genügt der Schriftform.  Der Vorstand ist beschlussfähig, wenn mindestens 4 Mitglieder ihre Stimme abgeben. Stimmenthaltungen werden für die Ermittlung der Stimmenmehrheit und der Zahl der abgegebenen Stimmen nicht berücksichtigt. Mit Zustimmung aller Vorstandsmitglieder können Beschlüsse abweichend von den satzungsgemäßen Formen und Fristen gefasst werden.</li>
                        <li className='pb-3'>Der Ablauf und die Beschlüsse der Vorstandssitzungen sind zu protokollieren. Das Protokoll ist durch einen der beiden Vorsitzenden und den Schriftführer zu unterzeichnen und allen Vorstandsmitgliedern innerhalb einer Woche zu übersenden.</li>
                      </ol>
                      <h4>§ 9 Mitgliederversammlung</h4>
                      <ol>
                        <li className='pb-3'>
                          Die Mitgliederversammlung ist zuständig für die Entscheidungen insbesondere in folgenden Angelegenheiten:
                          <ol>
                            <li>Änderung der Satzung,</li>
                            <li>Auflösung des Vereins,</li>
                            <li>Wahl und Abberufung der Mitglieder des Vorstands</li>
                            <li>Entgegennehmen des Jahresberichts und Entlastung des Vorstands,</li>
                            <li>Festsetzung der Mitgliedsbeiträge.</li>
                          </ol>
                        </li>
                        <li className='pb-3'>Mindestens einmal im Jahr, möglichst im ersten Quartal, ist vom Vorstand eine ordentliche Mitgliederversammlung einzuberufen. Die Einberufung erfolgt entweder schriftlich, per Fax oder über das Amtsblatt der Gemeinde Boxberg unter Einhaltung einer Frist von 2 Wochen und unter Angabe der vorläufigen Tagesordnung.
                          <br />Regelmäßige Schwerpunkte sind:
                          <ol>
                            <li>der Jahres- und Geschäftsbericht,</li>
                            <li>Entlastung des Vorstandes (alle 2 Jahre),</li>
                            <li>Wahl des Vorstandes (alle 2 Jahre),</li>
                            <li>die Abstimmung über den geprüften Kassenbericht.</li>
                          </ol>
                        </li>

                      </ol>
                      <h4>§ 10 Rechnungsprüfung</h4>
                      <ol>
                        <li className='pb-3'>Ein ordentlicher Rechnungsprüfer hat die Abrechnung und den Kassenbestand zu prüfen. Spätestens 4 Wochen vor der Versammlung übermittelt ihm der Schatzmeister die Abrechnung zur Prüfung.</li>
                        <li className='pb-3'>Dem Rechnungsprüfer steht es zu, nach eigenem Ermessen im Laufe des Jahres die Kassenverhältnisse zu prüfen.</li>

                      </ol>
                      <h4>§ 11 Auflösung des Vereins, Beendigung aus anderen Gründen, Wegfall steuerbegünstigter Zwecke</h4>
                      <ol>
                        <li className='pb-3'>Bei Auflösung des Vereins oder bei Wegfall steuerbegünstigter Zwecke fällt das Vermögen des Vereins an die Gemeinde Boxberg, die es unmittelbar und ausschließlich für die Förderung der Bildung und Erziehung zu verwenden hat.</li>
                        <li className='pb-3'>Die vorstehenden Bestimmungen gelten entsprechend, wenn der Verein aus einem anderen Grund aufgelöst wird oder seine Rechtsfähigkeit verliert.</li>

                      </ol>
                      <h4> § 12 Datenschutz im Verein</h4>
                      <ol>
                        <li className='pb-3'>Zur Erfüllung der Zwecke und Aufgaben des Vereins werden unter Beachtung der Vorgaben der EU-Datenschutz-Grundverordnung (DS-GVO) und des Bundesdatenschutzgesetzes (BDSG) personenbezogene Daten über persönliche und sachliche Verhältnisse der Mitglieder im Verein verarbeitet.</li>
                        <li className='pb-3'>Soweit in den jeweiligen Vorschriften beschriebenen Voraussetzungen vorliegen, hat jedes Vereinsmitglied insbesondere die folgenden Rechte:
                          <ul>
                            <li>das Recht auf Auskunft nach Artikel 15 DS- GVO,</li>
                            <li>das Recht auf Berechtigung nach Artikel 16 DS-GVO,</li>
                            <li>das Recht auf Löschung nach Artikel 17 DS-GVO,</li>
                            <li>das Recht auf Einschränkung der Verarbeitung nach Artikel 18 DS-GVO,</li>
                            <li>das Recht auf Datenübertragung nach Artikel 20 DS- GVO und</li>
                            <li>das Widerspruchsrecht nach Artikel 21 DS-GVO.</li>
                          </ul>
                        </li>
                        <li className='pb-3'>Den Organen des Vereins, allen Mitarbeitern oder sonst für den Verein Tätigen ist es untersagt, personenbezogene Daten unbefugt zu anderen als der jeweiligen Aufgabenerfüllung gehörenden Zweck zu verarbeiten, bekannt zu geben, Dritten zugänglich zu machen oder sonst zu nutzen. Diese Pflicht besteht auch über das Ausscheiden der oben genannten Personen aus dem Verein hinaus.</li>

                      </ol>
                      <h4>§ 13 Inkrafttreten</h4>
                      <ol>
                        <li className='pb-3'>Die Satzung wurde am 27.09.2006 beschlossen, am 16.12.2019 geändert und tritt mit ihrem Beschluss in Kraft. </li>

                      </ol>
                    </Card.Body>
                  </Card>
                )}

                {props.title === "Bankverbindung" && (
                  <Card style={{ maxWidth: "35rem" }}>
                    <Card.Body>
                      <h2>Bankverbindung</h2>
                      <p>Mitgliedsbeiträge des Fördervereins Freie Schule Boxberg/ O.L. e.V.
                        Mindestens 12,- Euro im Kalenderjahr (Januar bis Dezember)</p>
                      <h4 className='mb-3'>Sparkasse Oberlausitz Niederschlesien</h4>
                      <p><strong>IBAN:</strong> DE 46 85050100 00 870 015 86</p>
                      <p><strong>BIC:</strong> WELADED1GRL</p>

                    </Card.Body>
                  </Card>
                )}

                {props.title === "Eintritt" && (
                  <Card style={{ maxWidth: "35rem" }}>
                    <Card.Body>
                      <h2>Eintrittserklärung</h2>
                      <p>Die Eintrittserklärung können Sie sich herunterladen</p>
                      <p>
                        <a
                          className="btn btn-primary"
                          rel="noreferrer"
                          target="_blank"
                          href="https://fs-boxberg.de/server/infodocs/Eintritt.pdf"
                        >
                          Eintrittserklärung laden!
                        </a>
                      </p>

                    </Card.Body>
                  </Card>
                )}

                {props.title === "Unterstützer" && (
                  <Card style={{ maxWidth: "35rem" }}>
                    <Card.Body>
                      <h2>Besonder Dank gilt:</h2>
                      <br />* bluedesign Bautzen
                      <br />* Bäckerei Gerber, Förstgen
                      <br />* CVJM Boxberg e.V.
                      <br />* Dunapack Spremberg GmbH & Co.KG
                      <br />* Elektro-Wärme-Haustechnik Meisterbetrieb Mocha, Kringelsdorf
                      <br />* Entsorgungsbetrieb Hatsch
                      <br />* EWEX Engineering GmbH & Co.KG Boxberg
                      <br />* Familie Rodenbeck
                      <br />* Familienunternehmen Kunze „Residenz Sonnenhof“
                      <br />* Firma Walter Corporation
                      <br />* Fließen-, Platten- und Mosaikleger Sturm
                      <br />* Floristik Petra Laupitz
                      <br />* Fraktion „Die Linke“
                      <br />* Fußballverein Boxberg e.V.
                      <br />* Gaststätte „Am Wasserturm“ Weißwasser
                      <br />* Gostec GmbH Bau und Gleisbau
                      <br />* GTS GmbH Heizung, Lüftung, Sanitär
                      <br />* Haeuber Bau GmbH
                      <br />* Handwerksbetrieb Witschas
                      <br />* Herr Kunze „Lausitzer Bücherwurm“
                      <br />* Herr Luhmann
                      <br />* KKS Mertsch Schilderservice
                      <br />* Kranken- und Altenpflege Ursula Palmroth,
                      <br />* Landfleischerei Uhyst GmbH
                      <br />* Malerbetrieb und Service GbR Krupper
                      <br />* Nest VG,
                      <br />* Porenbetonwerk Europor Kringelsdorf
                      <br />* Schmiede und Metallbau Vater Klitten
                      <br />* SGL Lauchhammer
                      <br />* Sparkasse Oberlausitz-Niederschlesien
                      <br />* Stadtwerke Weißwasser
                      <br />* Tankstelle Klitten
                      <br />* Vattenfall Europe Generation AG
                      <br />* Vattenfall Europe Mining AG
                      <br />* Veolia Environnement Lausitz GmbH
                      <br />* Verein der Freiwilligen Feuerwehr Boxberg e.V.
                      <br />* Verein der Gartensparte „Am Schöps“ e.V.
                      <br />* Viereichener Fleisch und Wurstwaren GmbH, Rietschen
                      <br />* Weier Bau, Weißwasser
                      <br />* Westbogen Imbiss
                      <br />* Wotoka e.V. Sprey

                    </Card.Body>
                  </Card>
                )}

              </div>
            </Col>
          </Row>
        </Container>
      </div>

    </>)
}

export default MultiPage