import React from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function StandardCard(props) {
  // const bodyImage = props.img
  //   ? `<img src="${props.img}" alt="My Image" />`
  //   : "";
  //const golink = `app/${props.readmore}`;
  return (
    <Card className="h-100">
      {props.img && <Card.Img variant="top" src={props.img} />}
      <Card.Body>
        <Card.Title>{props.cardTitle}</Card.Title>
        {props.sub && (
          <Card.Subtitle className="pb-3">{props.sub}</Card.Subtitle>
        )}
        <Card.Text>
          {props.textBody}{" "}
          {props.cardTitle === "Schulessen" && (
            <p>
              <a href="mailto:hoyerswerda@saxonia-catering.de">
                hoyerswerda@saxonia-catering.de
              </a>
            </p>
          )}
        </Card.Text>
        {props.readmore && (
          <Link to={props.readmore} className="btn btn-outline-primary">
            alle Infos &raquo;
          </Link>
        )}
        {props.extUrl && (
          <a
            href={props.extUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-outline-primary"
          >
            Mehr erfahren &raquo;
          </a>
        )}
      </Card.Body>
    </Card>
  );
}
