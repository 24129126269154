import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <footer className="bg-dark text-white pt-5 pb-4">
        <div className="container text-center text-md-start">
          <div className="row text-center text-md-start">
            <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3">
              <h5 className="text-uppercase mb-4 font-weight-bold text-info">
                Freie Schule Boxberg /O.L.
              </h5>
              <p>Lernen in guter Atmosphäre</p>
            </div>

            <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3">
              <h5 className="text-uppercase mb-4 font-weight-bold text-info">
                Schule
              </h5>
              <p>
                <Link
                  to="/single/6"
                  className="text-white"
                  style={ { textDecoration: "none" } }
                >
                  Unterricht
                </Link>
              </p>
              <p>
                <Link
                  to="/single/7"
                  className="text-white"
                  style={ { textDecoration: "none" } }
                >
                  Schülerverkehr
                </Link>
              </p>
              <p>
                <Link
                  to="/single/9"
                  className="text-white"
                  style={ { textDecoration: "none" } }
                >
                  Klassen
                </Link>
              </p>

              <p>
                <Link
                  to="/single/10"
                  className="text-white"
                  style={ { textDecoration: "none" } }
                >
                  Lehrer &amp; Team
                </Link>
              </p>
            </div>
            <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3">
              <h5 className="text-uppercase mb-4 font-weight-bold text-info">
                Nützliche Links
              </h5>
              <p>
                <a
                  href="https://lernsax.de"
                  rel="noreferrer"
                  target="_blank"
                  className="text-white"
                  style={ { textDecoration: "none" } }
                >
                  LernSax
                </a>
              </p>
              <p>
                <a
                  href="https://mappspro.de/umfrage"
                  rel="noreferrer"
                  target="_blank"
                  className="text-white"
                  style={ { textDecoration: "none" } }
                >
                  Umfrage
                </a>
              </p>
              <p>
                <Link
                  to="/home#aktuell"
                  className="text-white"
                  style={ { textDecoration: "none" } }
                >
                  Aktuelles
                </Link>
              </p>
              <p>
                <Link
                  to="/single/4"
                  className="text-white"
                  style={ { textDecoration: "none" } }
                >
                  Schulleiter
                </Link>
              </p>
            </div>
            <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mt-3">
              <h5 className="text-uppercase mb-4 font-weight-bold text-info">
                Kontakt
              </h5>
              <p>
                <i className="fas fa-home me-3"></i> Boxberg/O.L., Sachsen DE
              </p>
              <p>
                <i className="fas fa-envelope me-3"></i>&nbsp;
                <a
                  className="text-white"
                  style={ { textDecoration: "none" } }
                  href="mailto:verwaltung@fs-boxberg.de"
                >
                  verwaltung@fs-boxberg.de
                </a>
              </p>
              <p>
                <i className="fas fa-phone me-3"></i>{ " " }
                <a
                  className="text-white"
                  style={ { textDecoration: "none" } }
                  href="tel:+493577432102"
                >
                  +49 35774 32 102
                </a>
              </p>

            </div>
          </div>

          <hr className="mb-4" />

          <div className="row align-items-center">
            <div className="col-md-7 col-lg-8">
              <p>
                &copy; 2022 Alle Rechte vorbehalten:&nbsp;
                <a href="/" style={ { textDecoration: "none" } }>
                  <strong className="text-info">Freie Schule Boxberg</strong>
                </a>
              </p>
            </div>

            <div className="col-md-5 col-lg-4">
              <div className="text-center text-md-end">
                <ul className="list-unstyled list-inline">
                  <li className="list-inline-item">
                    <a
                      style={ { fontSize: "23px" } }
                      rel="noreferrer"
                      target="_blank"
                      href="https://www.facebook.com/profile.php?id=100088762026375"
                      className="btn-floating btn-sm text-white"
                    >
                      <i className="fab fa-facebook"></i>
                    </a>
                  </li>

                  <li className="list-inline-item">
                    <a
                      style={ { fontSize: "23px" } }
                      rel="noreferrer"
                      target="_blank"
                      href="https://www.youtube.com/@videoteacher728"
                      className="btn-floating btn-sm text-white"
                    >
                      <i className="fab fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
