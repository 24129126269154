import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Helmet } from "react-helmet";

import SubnavDynamic from "../components/SubnavDynamic";

export default function Newpage(props) {
  // erzeugt aus den genannten props Variablen mit diesem Namen - cool
  const { headline, showSubnavbar, title } = props;

  // Mailadresse in const
  const emailAddress = "schule@fs-boxberg.de";

  return (
    <>
      <Helmet>
        <title>{ headline } - Freie Schule</title>
        <meta
          name="description"
          content="Schulinfo, Schulessen, Trägerverein"
        />
      </Helmet>
      <div className="bg-light">
        <Container>
          <Row>
            <Col>
              <div className="page">
                { showSubnavbar ? (
                  <SubnavDynamic links={ props.links } title={ title } />
                ) : (
                  <h2>{ headline }</h2>
                ) }

                { props.headline === "Satzung" && (
                  <Card style={ { maxWidth: "35rem" } }>
                    <Card.Body>
                      <h2>Trägerverein { props.headline } </h2>
                      <p>
                        Nachfolgend finden Sie hier die Satzung des Trägervereins der &quot;Freien Schule Boxberg&quot;
                        <br />
                        <br />
                        <a
                          className="btn btn-primary"
                          rel="noreferrer"
                          target="_blank"
                          href="https://fs-boxberg.de/server/infodocs/fsb-tv-satzung.pdf"
                        >
                          Satzung aufrufen!
                        </a>
                      </p>
                    </Card.Body>
                  </Card>
                ) }


                { props.headline === "Schulkleidung" && (
                  <Card style={ { maxWidth: "35rem" } }>
                    <Card.Body>
                      <h2>{ props.headline }</h2>
                      <p>
                        Sehr geehrte Eltern und Personensorgeberechtigte, <br />
                        <br /> wir bieten Ihnen die Möglichkeit Schulkleidung zu
                        bestellen. In der ersten{ " " }
                        <b>Schulwoche des neuen Schuljahres</b> können Sie Ihre
                        Bestellung im Schulbüro VERSANDKOSTENFREI aufgeben.
                        <br />
                        <br />
                        <a
                          className="btn btn-primary"
                          rel="noreferrer"
                          target="_blank"
                          href="https://fs-boxberg.de/server/infodocs/schoolgear.pdf"
                        >
                          Bestellformular aufrufen!
                        </a>
                      </p>
                    </Card.Body>
                  </Card>
                ) }


                { props.headline === "Aktuelles" && (
                  <div className="test">
                    <Card style={ { maxWidth: "35rem" } }>
                      <Card.Body>
                        <h2>{ headline } </h2>
                        <p>Der Trägerverein Freie Schule Boxberg/O.L. e.V. trifft sich zu seiner Sitzung in der Regel jeden letzten Mittwoch im Monat.
                        </p>
                        <p>Im öffentlichen Teil der Sitzung sind Schüler, Eltern, Angehörige, Förderer, Interessenten und alle sonstigen Mitwirkenden dazu eingeladen, mit uns in das Gespräch zu kommen.
                        </p>
                        <p>Wir freuen uns über konstruktive Kritik, Ideen, Anmerkungen und Anregungen, um die Arbeit an unserer Schule stetig weiter entwickeln zu können.</p>
                        <p className="text-center fw-bold">
                          Bitte nehmen Sie Kontakt mit uns auf, wenn Ihnen unsere Schule am Herzen liegt!
                          Wir freuen uns darauf.
                        </p>
                        <p>Den konkreten nächsten Sitzungstermin können Sie über <br /> <a href={ `mailto:${emailAddress}` }>schule@fs-boxberg.de</a><br /> oder auch persönlich bei jedem Mitglied des Vereins erfragen.</p>


                      </Card.Body>
                    </Card>


                  </div>
                ) }
                { props.headline === "Trägerverein" && (
                  <div className="test">
                    <Card style={ { maxWidth: "35rem" } }>
                      <Card.Body>
                        <h2>Der Schulträgerverein</h2>
                        <p>Mitglieder des Trägervereins Freie Schule Boxberg/O.L.</p>
                        <ul>
                          <li>Vorsitzende: Maike Seffner</li>
                          <li>Stellvertreter: Arian Leffs</li>
                          <li> Jan Molenda</li>
                          <li> Guido Springer</li>
                          <li> Claudius Urban</li>
                          <li> Silke Kittan</li>
                          <li> Michaela Gärtner</li>
                          <li> Ute Schwarz</li>
                        </ul>
                        <h4>Berater</h4>
                        <p>Kontakt über: <a href={ `mailto:${emailAddress}` }>schule@fs-boxberg.de</a></p>
                      </Card.Body>
                    </Card>


                  </div>
                ) }
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
