import React, { useRef, useEffect } from "react";

import Container from "react-bootstrap/Container";
import RowCommercial from "./RowCommercial";

import RowInfo from "./RowInfo";
import RowStarter from "./RowStarter";

export default function Section3() {
  const myRef = useRef();

  function scrollToComponent() {
    if (window.location.hash === "#aktuell") {
      myRef.current.scrollIntoView();
      myRef.current.focus();
    }
  }

  useEffect(() => scrollToComponent(), []);

  return (
    <>
      <div className="bg-light" ref={myRef} id="aktuell">
        <Container className="pt-3 pb-3">
          <RowStarter />
        </Container>
      </div>
      <div>
        <Container className="mt-3">
          <RowInfo />
        </Container>
      </div>
      <div className="bg-light">
        <Container className="pt-3 pb-3">
          <RowCommercial />
        </Container>
      </div>
    </>
  );
}
