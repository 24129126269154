import React, { useState } from "react";
import axios from "axios";

function submitForm(data, setResponse) {
  axios
    .post("https://www.fs-boxberg.de/server/process-form.php", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      console.log(response.data);
      setResponse(response.data);
    })
    .catch((error) => {
      console.error(error);
    });
}

function Contact() {
  const [formData, setFormData] = useState({});
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  function handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }

  function handleSubmit(event) {
    event.preventDefault();

    // leere Mail prüfen
    if (!formData.name || !formData.email) {
      setError("Name and email are required");
      return;
    }

    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailRegex.test(formData.email)) {
      setError("Invalid email address");
      return;
    }

    setError(null);
    submitForm(formData, setResponse);
  }

  function resetForm() {
    setResponse(null);
    setFormData({});
  }

  return (
    <>
      <h1>Trag dich ein!</h1>
      {error && <div className="alert alert-danger">{error}</div>}
      {response ? (
        <>
          <div className="alert alert-success">
            <strong>Meldung: </strong>
            {JSON.stringify(response, null, 2)}
          </div>
          <button className="btn btn-primary" onClick={resetForm}>
            Weitere Person registrieren?
          </button>
        </>
      ) : (
        <form onSubmit={handleSubmit}>
          <label>
            Name:
            <input
              type="text"
              name="name"
              className="form-control mb-3"
              value={formData.name || ""}
              onChange={handleChange}
            />
          </label>
          <br />
          <label>
            Email:
            <input
              type="email"
              className="form-control mb-3"
              name="email"
              value={formData.email || ""}
              onChange={handleChange}
            />
          </label>
          <br />
          <button type="submit" className="btn btn-primary">
            Senden
          </button>
        </form>
      )}
    </>
  );
}

export default Contact;
