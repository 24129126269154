import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import Subnavbar from "../components/Subnavbar";


export default function Single() {
  let { postId } = useParams();
  const [htmlContent, setHtmlContent] = useState({ __html: "" });
  const [htmlTitle, setHtmlTitle] = useState("Klassen");

  // immer zu 430 scrollen
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 430);
  }, [location]);

  const state = postId;

  useEffect(() => {

    const webUrl = `https://fs-boxberg.de/server/live_post.php?id=${state}`;
    console.log(state);
    switch (state) {
      case "4":
        setHtmlTitle("Aktuelles");
        break;
      case "6":
        setHtmlTitle("Unterrichtszeiten");
        break;
      case "7":
        setHtmlTitle("Schülerverkehr");
        break;
      case "9":
        setHtmlTitle("Klassen");
        break;
      case "10":
        setHtmlTitle("Kollegium");
        break;
      default:
        setHtmlTitle("Schulinfo");
    }
    axios
      .get(webUrl)
      .then((response) => {
        // Set the HTML content to the response data:
        setHtmlContent({ __html: response.data });
      })
      .catch((error) => {
        console.error(error);
      });
  }, [state]);
  return (
    <>
      <Helmet>
        <title>{ htmlTitle } - Freie Schule</title>
        <meta name="description" content="Tag der offenen Tür" />
      </Helmet>
      <div className="bg-light">
        <Subnavbar />
        <Container>
          <div style={ { padding: "0" } }>
            {/* this element will have its inner HTML set to the HTML content from the response */ }
            <div id="myElement" dangerouslySetInnerHTML={ htmlContent } />
          </div>
        </Container>
      </div>
    </>
  );
}
