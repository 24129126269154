import React from "react";
import { Row } from "react-bootstrap";
import StandardCard from "./StandardCard";
import luk from "../img/infocard-luk.png";
import times from "../img/infocard-times.png";
import bus from "../img/infocard-bus.png";

export default function RowInfo() {
  return (
    <>
      <Row>
        <h2 className="section-head text-center">Schul-Infoboard</h2>
      </Row>
      <Row className="mb-5">
        <div className="col-md-4 mb-3">
          <StandardCard
            cardTitle="Klassen, Lehrer & Team"
            img={ luk }
            sub=""
            textBody="Übersicht zu unseren Klassen, das Lehrerkollegium und der Verwaltung"
            readmore="/single/9"
          />
        </div>
        <div className="col-md-4 mb-3">
          <StandardCard
            cardTitle="Informationen zum Schülerverkehr"
            img={ bus }
            sub="Aktuelle Busfahrpläne"
            textBody="findest du in der Übersicht als PDF"
            readmore="/single/7"
          />
        </div>
        <div className="col-md-4 mb-3">
          <StandardCard
            cardTitle="Ferien- und Unterrichtszeiten"
            img={ times }
            textBody="Nächste Ferien: 23.12.2024- 03.01.2025"
            sub="Stunden und Pausen"
            readmore="/single/6"
          />
        </div>
      </Row>
    </>
  );
}
