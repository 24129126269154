import React from 'react'
import { Navbar, Nav, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function SubnavDynamic({ links, title }) {
    if (!links || links.length === 0) {
        // Wenn die links-Prop leer ist, wird nichts gerendert
        return null;
    }

    const navbartitle = (title) ? title : 'Navigation';

    return (
        <div>
            <Navbar bg="light" expand="lg">
                {/* Adding margin left for layout reasons */}
                <Container>
                    <Navbar.Brand className="card-subtitle">
                        {navbartitle}
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="second-navbar-nav" />
                    <Navbar.Collapse id="second-navbar-nav">
                        <Nav className="me-auto px-2">
                            {links.map((link, index) => (
                                <Nav.Link key={index}>
                                    <Link to={link.to} className="nav-link">
                                        {link.label}
                                    </Link>
                                </Nav.Link>
                            ))}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    );
}
