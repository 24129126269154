import React from 'react';
import testbild from '../img/schoolbanner.jpeg';

function Designed() {
    return (
        <>
            <div className="container-xxl bg-light">
                <div className="row">
                    <div className="col-md-3 col-12 col-lg-3 left-nav">
                        <div className="card my-4">
                            <div className="card-body">
                                <h6 className="card-subtitle mb-2">My Account</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 col-12 col-lg-6">
                        <div style={ { maxWidth: '680px', margin: '0 auto' } }>
                            <div className="card my-4">
                                <div className="card-body">
                                    <h5 className="card-title">Back to School</h5>
                                    <h6 className="card-subtitle mb-2 text-body-secondary">Geposted von Sandro Biewig</h6>
                                    <img style={ { width: '100%', height: 'auto', marginBottom: '20px' } } src={ testbild } alt="Banner" />
                                    <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                    <a href="/" className="card-link">Card link</a>
                                    <a href="/" className="card-link">Another link</a>
                                </div>
                            </div>
                            <div className="card my-4">
                                <div className="card-body">
                                    <h5 className="card-title">Back to School</h5>
                                    <h6 className="card-subtitle mb-2 text-body-secondary">Geposted von Sandro Biewig</h6>
                                    <img style={ { width: '100%', height: 'auto', marginBottom: '20px' } } src={ testbild } alt="Banner" />
                                    <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                    <a href="/" className="card-link">Card link</a>
                                    <a href="/" className="card-link">Another link</a>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="col-md-3 col-12 col-lg-3 right-nav">
                        {/* <!-- Inhalt der rechten Navigationsspalte --> */ }
                        <div style={ { background: 'yellow' } }>
                            <div className="card my-4">
                                <div className="card-body">
                                    <h5 className="card-title">Who Is Online?</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Designed;