import React from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

export default function Market() {
  let { productId } = useParams();

  return (
    <>
      <Helmet>
        <title>Markt - Freie Schule</title>
        <meta name="description" content="Tag der offenen Tür" />
      </Helmet>
      <Container>
        <h1>Hardware Marketplace</h1>
        <p>
          Product with ID: <b>&quot;{productId}&quot;</b>
        </p>
        <div style={{ padding: "20px" }}>
          Dynamic routes can be used for slugs and Database queries.
        </div>
      </Container>
    </>
  );
}
