import React from "react";
import { Row } from "react-bootstrap";
import StandardCard from "./StandardCard";
// import fawlogo from "../img/faw-logo-360.png";
// import sc_logo from "../img/sc_logo.jpeg";
// import werbungpaul from "../img/werbung-paul.webp";

export default function RowCommercial() {
  return (
    <>
      <Row>
        <h2 className="section-head text-center">Für Schüler</h2>
      </Row>
      <Row className="mb-5">
        <div className="col-md-4 mb-3">
          <StandardCard
            cardTitle="Schulkleidung"
            textBody="Informationen zum Angebot und Bestellung der Schulkleidung"
            readmore="/schoolgear"
          />
        </div>
        <div className="col-md-4 mb-3">
          <StandardCard
            cardTitle="Schulessen"
            textBody="Saxonia Logo - Bestellungen online oder per E-Mail und über die App „Mein Menü-Saxonia Catering Ost“"
          />
        </div>
        <div className="col-md-4 mb-3">
          <StandardCard
            cardTitle="Berufsorientierung"
            textBody="Fortbildungsakademie der Wirtschaft (FAW) gGmbH "
            extUrl="https://www.faw.de"
          />
        </div>
      </Row>
    </>
  );
}
