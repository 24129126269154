import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoginForm from "../components/LoginForm";

export default function Login() {
  const users = [
    {
      username: "franky",
      password: "password1",
    },
    {
      username: "user2",
      password: "password2",
    },
    // Weitere Benutzer
  ];
  const history = useNavigate();

  function handleLogin(username, password) {
    const user = users.find(
      (user) => user.username === username && user.password === password
    );
    // const msg = document.querySelector(".msg");
    if (user) {
      // Navigieren zur Dashboard-Komponente
      //msg.innerHTML = "Login gültig!";
      sessionStorage.setItem("loggedIn", true);
      sessionStorage.setItem("appuser", user.username);
      history("/home");
    } else {
      // Anzeigen einer Fehlermeldung
      alert("Passwort ungültig!");
    }
  }
  // Logeed User
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isUsername, setIsUsername] = useState("");

  useEffect(() => {
    const item = sessionStorage.getItem("loggedIn");
    const appuser = sessionStorage.getItem("appuser");
    if (item) {
      setIsLoggedIn(true);
      setIsUsername(appuser);
    } else {
      setIsLoggedIn(false);
      setIsUsername("");
    }
  }, []);

  const handleClick = (e) => {
    const username = document.getElementById("username").value;
    const password = document.getElementById("password").value;
    e.preventDefault();
    handleLogin(username, password);
  };

  const handleLogout = () => {
    sessionStorage.removeItem("loggedIn");
    sessionStorage.removeItem("appuser");
    setIsLoggedIn(false);
  };

  return (
    <div>
      {isLoggedIn ? (
        // Benutzer ist angemeldet, also können Sie eine Meldung ausgeben oder einen anderen Teil der App rendern
        <>
          <div className="alert alert-warning">
            <strong>Systeminfo: </strong> Du bist angemeldet {isUsername}!
          </div>
          <button className="btn btn-secondary" onClick={handleLogout}>
            Abmelden
          </button>
        </>
      ) : (
        <LoginForm handleClick={handleClick} />
      )}
    </div>
  );
}
