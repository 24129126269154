import React from 'react';
import { Container, Col, Row, Card, Button } from 'react-bootstrap';

const ImageGrid = ({ images }) => {
    return (
        <Container>
            <Row>
                { images.map((image, index) => (
                    <Col key={ index } xs={ 12 } sm={ 6 } md={ 4 } lg={ 3 } xl={ 2 } className="mb-4">
                        <Card className="h-100">
                            <Card.Img variant="top" src={ image.src } alt={ image.alt } />
                            <Card.Body className="d-flex flex-column">
                                <Button
                                    rel="noreferrer"
                                    target="_blank"
                                    href="https://fs-boxberg.de/server/infodocs/ordersheet.pdf"
                                    variant="primary" className="mt-auto">
                                    Bestellen
                                </Button>
                            </Card.Body>
                        </Card>
                    </Col>
                )) }
            </Row>
        </Container>
    );
};

export default ImageGrid;